import { combineReducers } from 'redux';
import {
  EXIT_CONNECTED_DEVICES_EDIT_MODE, DEVICES_APPEND_SELECTED_ROW, DEVICES_UNSELECT_ROW,
  DEVICES_SELECT_ALL, DEVICES_UNSELECT_ALL, SET_DEVICES_EDIT_MODE, DEVICES_ADD_ROW_ERROR, DEVICES_REMOVE_ROW_ERRORS,
  DEVICES_REMOVE_ALL_ROW_ERRORS,
  GET_DEVICES,
  GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY,
  REFRESH_DEVICES,
  UPDATE_DEVICES_EDIT_MODE,
  DEVICES_ADD_ALL_ROW_ERROR,
  GET_TELEMATIC_ACCOUNT,
} from '../../../../actions';
import { DeviceContext } from '../../../../../types';
import { normalizeTelematicAccountDevicesContent } from '../../../../normalizers';
import { uuidv4 } from '../../../../../utils/uuid';
import { SET_DEVICES_PREV_ACTION } from '../../../../actions/telematicAccounts/devices/prevAction.actions';
import {
  DEVICES_ACTION,
} from '../../../../../modules/Telematics/TelematicAccountDetails/Components/DevicesContent.constants';

const initialStateIsEnabled = false;

const id = (deviceContext = DeviceContext.CONNECTED) => (state = null, { type }) => {
  switch (type) {
    case SET_DEVICES_EDIT_MODE[deviceContext]:
    case UPDATE_DEVICES_EDIT_MODE[deviceContext]:
      return uuidv4();
    default:
      return state;
  }
};

const isEnabled = (deviceContext = DeviceContext.CONNECTED) => (state = initialStateIsEnabled, { type }) => {
  switch (type) {
    case SET_DEVICES_EDIT_MODE[deviceContext]:
      return true;
    case EXIT_CONNECTED_DEVICES_EDIT_MODE:
      return false;
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
    case GET_TELEMATIC_ACCOUNT.SUCCESS:
      return initialStateIsEnabled;
    default:
      return state;
  }
};

const rowSelectionProps = (deviceContext = DeviceContext.CONNECTED) => (state = null, { type, payload }) => {
  switch (type) {
    case SET_DEVICES_EDIT_MODE[deviceContext]:
      return { ...payload.props };
    case EXIT_CONNECTED_DEVICES_EDIT_MODE:
      return null;
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
    case GET_TELEMATIC_ACCOUNT.SUCCESS:
      return null;
    default:
      return state;
  }
};

const initialState = [];

const rows = (deviceContext = DeviceContext.CONNECTED) => (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DEVICES_EDIT_MODE[deviceContext]:
      return payload.rows;
    case UPDATE_DEVICES_EDIT_MODE[deviceContext]: {
      return payload.rows.map((updatedRow) => {
        const currentRow = state.find((r) => r.deviceId === updatedRow.deviceId);
        return !currentRow ? updatedRow
          : { ...updatedRow, errors: currentRow.errors, isSelected: currentRow.isSelected };
      });
    }
    case DEVICES_APPEND_SELECTED_ROW[deviceContext]: {
      const row = state.find((r) => r.deviceId === payload.row.deviceId);
      row.isSelected = true;
      return [...state];
    }
    case DEVICES_UNSELECT_ROW[deviceContext]: {
      const row = state.find((r) => r.deviceId === payload.row.deviceId);
      row.isSelected = false;
      return [...state];
    }
    case DEVICES_SELECT_ALL[deviceContext]:
      return state.map((row) => ({ ...row, isSelected: true }));
    case DEVICES_UNSELECT_ALL[deviceContext]:
      return state.map((row) => ({ ...row, isSelected: false }));
    case DEVICES_ADD_ROW_ERROR[deviceContext]: {
      const row = state.find((r) => r.deviceId === payload.row.deviceId);
      const { errors } = row;
      const index = errors.indexOf(payload.error);
      if (index === -1) {
        row.errors.push(payload.error);
      }
      return [...state];
    }
    case DEVICES_ADD_ALL_ROW_ERROR[deviceContext]: {
      payload.rows.forEach(({ deviceId, errors }) => {
        const row = state.find((r) => r.deviceId === deviceId);
        const { errors: currentErrors } = row;
        errors.forEach((error) => {
          const index = currentErrors.indexOf(error);
          if (index === -1) {
            row.errors.push(error);
          }
        });
      });
      return [...state];
    }
    case DEVICES_REMOVE_ROW_ERRORS[deviceContext]: {
      const row = state.find((r) => r.deviceId === payload.row.deviceId);
      const { errors } = row;
      payload.errors.forEach((error) => {
        const index = errors.indexOf(error);
        if (index !== -1) {
          errors.splice(index, 1);
        }
      });
      return [...state];
    }
    case DEVICES_REMOVE_ALL_ROW_ERRORS[deviceContext]: {
      const row = state.find((r) => r.deviceId === payload.row.deviceId);
      row.errors = [];
      return [...state];
    }
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
    case GET_TELEMATIC_ACCOUNT.SUCCESS:
      return initialState;
    default:
      return state;
  }
};

const submittedRows = (deviceContext = DeviceContext.CONNECTED) => (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DEVICES[deviceContext].SUCCESS:
    case REFRESH_DEVICES[deviceContext].SUCCESS: {
      const { submittedDeviceRowIds } = payload.config.reduxSourceAction.payload;
      if (submittedDeviceRowIds?.length > 0) {
        const devices = normalizeTelematicAccountDevicesContent(payload.data, deviceContext);
        return deviceContext === DeviceContext.NEW
          ? submittedDeviceRowIds
            .filter((submittedDeviceId) => !devices.includes(submittedDeviceId))
            .map((submittedDeviceId) => ({ deviceId: submittedDeviceId }))
          : devices.filter((row) => submittedDeviceRowIds.includes(row.deviceId));
      }
      return state;
    }
    case GET_DEVICES[deviceContext].FAIL:
      return state;
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
    case GET_TELEMATIC_ACCOUNT.SUCCESS:
    case SET_DEVICES_PREV_ACTION[deviceContext]: {
      const prevActionName = payload.prevAction?.name;
      if (!prevActionName || DEVICES_ACTION.SUBMIT_VEHICLES_FORM.includes(prevActionName)) {
        return initialState;
      }
      return state;
    }
    default:
      return state;
  }
};

export default (deviceContext = DeviceContext.CONNECTED) => combineReducers({
  id: id(deviceContext),
  isEnabled: isEnabled(deviceContext),
  rowSelectionProps: rowSelectionProps(deviceContext),
  rows: rows(deviceContext),
  submittedRows: submittedRows(deviceContext),
});
