import { DeviceContext } from '../../../../../types';
import { GET_TELEMATIC_ACCOUNT, GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY } from '../../../../actions';
import { SET_DEVICES_PREV_ACTION } from '../../../../actions/telematicAccounts/devices/prevAction.actions';

const initialState = {
  id: null,
  name: null,
};

export default (deviceContext = DeviceContext.CONNECTED) => (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DEVICES_PREV_ACTION[deviceContext]:
      return payload.prevAction;
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
    case GET_TELEMATIC_ACCOUNT.SUCCESS:
      return initialState;
    default:
      return state;
  }
};
