import {
  GET_TELEMATIC_ACCOUNT, GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY, RESET_COMPANY_CONTEXT,
} from '../../../../actions';
import { SET_DEVICES_PAGINATION } from '../../../../actions/telematicAccounts/devices/pagination.actions';
import { DeviceContext } from '../../../../../types';

const INIT_PAGINATION = { page: 1, size: 50 };

export default (deviceContext = DeviceContext.CONNECTED) => (state = { ...INIT_PAGINATION }, { type, payload }) => {
  switch (type) {
    case SET_DEVICES_PAGINATION[deviceContext]:
      return { page: payload.page || state.page, size: payload.size || state.size };
    case RESET_COMPANY_CONTEXT:
      return { ...state, page: 1 };
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
    case GET_TELEMATIC_ACCOUNT.SUCCESS:
      return INIT_PAGINATION;
    default:
      return state;
  }
};
